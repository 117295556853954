import React, { useEffect, useCallback, useRef, useMemo, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import growthbkg from '../../src/img/careers/growth-background.jpg';
import { to, useSpring, useSprings, animated as a } from 'react-spring';
// import wanted from '../../src/img/CAREERS-wanted-wall.png';
import { useStaticQuery, graphql } from 'gatsby';
// import PortalToTop from '../components/PortalToTop';
import { Link } from 'gatsby';
import quoteImg from '../../src/img/careers/Brock.jpg';
import quoteBkg from '../../src/img/careers/quote.svg';
import arrow from '../../src/img/careers/arrow.svg';
import arrowBlk from '../../src/img/careers/arrow-black.svg';

const StyledImageSlider = styled.div`
  .full-width-background {
    position: relative;
  }
  .arrows {
    position: absolute;
    top: calc(100vw * 0.3 - 50px);
    left: 58.68%;
    display: block;
    z-index: 3;
    @media screen and (max-width: 1000px) {
      top: auto;
      left: auto;
      right: 0;
      bottom: 210px;
      height: calc(100vw * 0.6437);
      position: static;
    }
  }
  .arrow {
    display: inline-block;
    width: 115px;
    height: 100px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    cursor: pointer;
    transition: filter 540ms ease;
    &:hover {
      filter: brightness(1.2) contrast(0.8);
    }
    &:active {
      filter: brightness(0.8) saturate(1.2);
      transition-duration: 80ms;
    }
    &.arrow-prev {
      background-color: #44c3e7;
    }
    &.arrow-next {
      background-color: #067eba;
      transform: rotate(180deg);
    }
    @media screen and (max-width: 1000px) {
      display: block;
      padding: 0;
      height: 50%;
      width: 25vw;
    }
  }
  .slider {
    /* min-height: 1050px; */
    .slide {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      position: relative;
      max-width: 100%;
      width: 100%;
    }
    /* .slide-0 img,
    .slide-1 img,
    .slide-2 img,
    .slide-3 img,
    .slide-4 img {
      display: none;
      &.in {
        display: block;
      }
    } */
    .content-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 58.68%;
      width: 100%;
      background: linear-gradient(84.99deg, #172e56 10.92%, #067eba 93.66%);
      align-self: stretch;
      margin-bottom: 50px;
      padding: 15px 122px 15px 123px;
      box-sizing: border-box;
      @media (max-width: 1300px) {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media screen and (max-width: 1000px) {
        max-width: 100%;
        margin-bottom: auto;
        padding-top: 2em;
        padding-bottom: 2em;
      }
      @media screen and (max-width: 500px) {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
      h2 {
        font-family: Oswald;
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 52px;
        margin-top: 0;
        margin-bottom: 34px;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
      }
    }
    .image-slider {
      overflow: hidden;
      position: relative;
      width: 100%;
      padding-top: 30%;
      img {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
    .quote {
      background-color: #a9d23f;
      max-width: 72.1%;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 48px 30px 48px 55px;
      box-sizing: border-box;
      display: flex;
      height: calc(100vw * 0.162);
      align-items: center;
      z-index: 5;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
        position: relative;
        height: max-content;
        padding: 1em;
      }
      .quoteinner {
        max-width: 915px;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }
      img {
        min-width: 100px;
        max-width: 135px;
        width: calc((135 / 1440) * 100vw);
        height: auto;
        flex: 1 1 auto;
        align-self: center;
        margin-right: 39px;
        border: 4px solid white;
        border-radius: 100%;
      }
      p {
        font-family: Roboto;
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        @media screen and (max-width: 1200px) {
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
        }
      }
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        letter-spacing: 0.02em;
        display: block;
        @media screen and (max-width: 1200px) {
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
        }
      }
      .quote-txt {
        flex: 1 1 auto;
        padding: 11px 0;
        max-width: 640px;
      }
      .quote-img {
        background-image: url(${quoteBkg});
        background-repeat: no-repeat;
        background-position: 44px center;
        min-width: 100px;
        flex: 1 1 auto;
        align-self: stretch;
        @media screen and (max-width: 650px) {
          display: none;
        }
      }
      @media screen and (max-width: 500px) {
        .quoteinner {
          display: block;
        }
        img {
          float: right;
          width: 80px;
          margin-right: 15px;
          margin-left: 10px;
        }
        .quote-txt {
          padding-top: 0;
        }
      }
    }
    .image-1,
    .image-4 {
      max-width: 41.32%;
    }
    .image-2,
    .image-3 {
      margin-top: -50px;
    }
    .image-1 {
      padding-top: 30%;
    }
    .image-2 {
      max-width: 33.888%;
      padding-top: 46.3%;
    }
    .image-3 {
      max-width: 24.792%;
      padding-top: 30.1%;
      align-self: flex-start;
    }
    .image-4 {
      padding-top: calc(30.1% - 50px);
      align-self: flex-start;
    }
    img {
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
    @media screen and (max-width: 1000px) {
      .image-1 {
        max-width: 100%;
        padding-top: 73.27%;
      }
      .image-2 {
        display: none;
      }
      .image-3 {
        display: none;
      }
      .image-4 {
        margin-top: auto;
        padding-top: 64.37%;
        max-width: 75%;
      }
    }
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const StyledDarkSection = styled.div`
  max-width: 100%;
  width: 100%;
  background: linear-gradient(7.78deg, #172e56 21.07%, #067eba 180.43%);
  background-image: url(${growthbkg});
  background-size: cover;
  padding: 84px 0;
  .row {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    .container {
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      h2 {
        text-transform: uppercase;
      }
      h2,
      h3 {
        @media screen and (max-width: 750px) {
          width: calc(100vw - 2em);
          padding-left: 1em;
          padding-right: 1em;
        }
      }
      h3 br {
        @media screen and (max-width: 360px) {
          display: none;
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 980px;
      width: 100%;
      margin: 65px auto 0;
      @media screen and (max-width: 900px) {
        display: block;
      }
      .column {
        max-width: 30%;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        @media screen and (max-width: 900px) {
          display: flex;
          max-width: 80%;
          text-align: left;
          p {
            margin-left: 1em;
            @media screen and (max-width: 900px) {
              margin-top: 10px;
              margin-bottom: 22px;
            }
            @media screen and (max-width: 450px) {
              font-size: 18px;
            }
          }
        }
        img {
          height: 102px;
          width: auto;
          margin-bottom: 22px;
          @media screen and (max-width: 900px) {
            height: auto;
            width: 85px;
          }
        }
      }
    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 34px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 52px;
  }
  h3 {
    margin-bottom: 0;
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
  }
  p {
    margin-bottom: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.4;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const StyledWhiteSection = styled.div`
  max-width: 100%;
  width: 100%;
  background: #fff;
  padding: 68px 0;
  .row {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    .container {
      text-align: center;
      * {
        color: #114b7a;
      }
      @media screen and (max-width: 400px) {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    .slideWrap {
      overflow: hidden;
    }
    .flex {
      display: grid;
      grid-template-columns: repeat(auto-fit, 254px);
      grid-template-rows: 1fr;
      gap: 35px;
      margin-top: 49px;
      width: 5500px;
      .column {
        width: 254px;
        margin: 0 auto;
        text-align: center;
      }
    }
    .button-container {
      max-width: 400px;
      margin: 61px auto 0;
      display: flex;
      justify-content: center;
      button {
        width: 115px;
        height: 30px;
        background-color: transparent;
        background-image: url(${arrowBlk});
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        cursor: pointer;
      }
      button:nth-child(2) {
        transform: rotate(180deg);
      }
    }
  }
  h2 {
    margin-top: 0;
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  p {
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #067eba;
  }
  p:last-child {
    margin-bottom: 0;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const StyledIntro = styled.div`
  background-color: #fff;
  color: #585858;
  margin: 0;
  padding: 78px 0 84px;
  .styled-intro-wrapper {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1040px) {
      flex-direction: column;
    }
  }
  .rainbow-box {
    max-width: 514px;
    width: 100%;
    border-width: 8px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(223deg, #cc6f54, #be4476, #835489, #0476b0, #0476b0);
    padding: 48px 0;
    margin-right: -80px;
    z-index: 2;
    @media screen and (max-width: 1040px) {
      margin-right: 0;
      margin-bottom: 2.6em;
      width: 90%;
    }
    h2,
    p {
      margin: 0;
      margin-left: -8px;
      background-color: #fff;
      max-width: 395px;
      width: 100%;
    }
    h2 {
      padding-top: 30px;
      padding-bottom: 23px;
    }
    p {
      padding-bottom: 30px;
    }
  }
  .image-wrapper {
    max-width: 548px;
    width: 100%;
    height: auto;
    @media screen and (max-width: 1040px) {
      width: 90%;
    }
  }
  h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 23px;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const StyledDiv = styled.div`
  .no-posts {
    padding: 4rem 25px;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    text-align: center;
    background: white;
    color: black;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1em;
    box-sizing: border-box;
  }
`;

const JobPostings = styled.div`
  min-height: 50vh;
  background: white;
  .full-width-background {
    background: rgba(188, 224, 244, 0.3);
  }
  .inner-content {
    width: calc(100vw - 200px);
    min-height: 50vh;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    @media (max-width: 1040px) {
      width: calc(100vw - 100px);
    }
    @media (max-width: 700px) {
      width: calc(100% - 60px);
    }
  }
  .row {
    max-width: 100%;
    width: 100%;
    .job {
      color: #585858;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      flex-direction: row;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      border-bottom: 1px solid #d9d9d9;
      padding: 50px 25px;
      box-sizing: border-box;
      @media screen and (max-width: 850px) {
        display: block;
      }
      @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
      }
      a {
        color: #067eba;
        font-weight: 700;
      }
      .left {
        max-width: 237px;
        padding-right: 35px;
        width: 100%;
        box-sizing: border-box;
        @media screen and (max-width: 850px) {
          max-width: 100%;
          margin-bottom: 1em;
        }
      }
      .right {
        max-width: calc(100% - 258px);
        width: 100%;
        p {
          margin-top: 0;
        }
        @media screen and (max-width: 850px) {
          max-width: 100%;
        }
      }
    }
    h2 {
      color: #172e56;
      font-weight: 200;
      font-size: 48px;
      line-height: 52px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 79px;
    }
  }
  h4 {
    font-family: 'Roboto';
    color: rgba(23, 46, 86, 1);
    font-size: 21px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    @media (max-width: 1040px) {
      font-size: 1.6rem;
    }
  }
  h4:hover {
    text-decoration: underline;
  }
`;

const BenefitsSlider = ({ benefits }) => {
  const columnWidth = 289;
  const curIndex = useRef(1);

  const [autoplay, setAutoplay] = useState(true);
  const [startPos, setStartPos] = useState(columnWidth * -1.5);

  useEffect(() => {
    const recenter = () => {
      const width = window.innerWidth;
      const mid = width * 0.5;
      const next = Math.ceil(mid / columnWidth) * columnWidth;
      const leftEdge = mid - next;
      const center = leftEdge - (columnWidth - 35) / 2; //exclude the 35px gap

      setStartPos(center);
      set.start({ transform: `translateX(${center}px)`, immediate: true });
    };
    recenter();

    window.addEventListener('resize', recenter);
    return () => {
      window.removeEventListener('resize', recenter);
    };
  }, [columnWidth]);

  const benefitList = useMemo(() => {
    //add items on far side of the list to make room for looping
    return benefits.concat(benefits, benefits);
  }, [benefits]);

  const [props, set] = useSpring(() => {
    return { transform: `translateX(${-columnWidth + startPos}px)` };
  });

  const slideForward = useCallback(() => {
    curIndex.current = (curIndex.current + 1) % 6;

    if (curIndex.current === 0) {
      set.start({ transform: `translateX(${columnWidth + startPos}px)`, immediate: true });
    }
    set.start({ transform: `translateX(${-1 * curIndex.current * columnWidth + startPos}px)`, immediate: false });
  }, [curIndex, startPos, set]);

  const slideBack = useCallback(() => {
    curIndex.current = curIndex.current === 0 ? 5 : curIndex.current - 1;

    if (curIndex.current === 5) {
      set.start({ transform: `translateX(${-6 * columnWidth + startPos}px)`, immediate: true });
    }
    set.start({ transform: `translateX(${-1 * curIndex.current * columnWidth + startPos}px)`, immediate: false });
  }, [curIndex, startPos, set]);

  useEffect(() => {
    let timer = null;
    function nextSlide() {
      slideForward();
      timer = setTimeout(nextSlide, 3500);
    }
    if (autoplay) {
      nextSlide();
    } else {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [autoplay, startPos, curIndex]);

  return (
    <>
      <div className="slideWrap">
        <a.div className="flex" style={props}>
          {benefitList.map((item, index) => (
            <div className="column" key={index}>
              {item.icon && <img src={item.icon.publicURL} />}
              <p dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
          ))}
        </a.div>
      </div>
      <div className="button-container">
        <button
          onClick={() => {
            setAutoplay(false);
            slideBack();
          }}
        ></button>
        <button
          onClick={() => {
            setAutoplay(false);
            slideForward();
          }}
        ></button>
      </div>
    </>
  );
};

const CareerPage = ({ fields }) => {
  const jobPosts = useStaticQuery(
    graphql`
      {
        allWpJobPost(sort: { order: DESC, fields: date }) {
          edges {
            node {
              id
              title
              content
              slug
              custom {
                customExcerpt
              }
            }
          }
        }
      }
    `
  );

  const [activeJobPost, setActiveJobPost] = useState(-1);

  const currentSlide = useRef(0);

  const [autoplay, setAutoplay] = useState(true);

  const curSlideFn =
    (delay, isReverse = false) =>
    (i) => {
      const prevSlide = isReverse
        ? currentSlide.current === 3
          ? 0
          : currentSlide.current + 1
        : currentSlide.current === 0
        ? 3
        : currentSlide.current - 1;
      if (i === prevSlide) {
        return {
          delay: delay,
          yPos: 0,
          direction: isReverse ? -1 : 1,
          zIndex: 0,
          immediate: true,
        };
      } else if (i === currentSlide.current) {
        return {
          delay: delay,
          from: { yPos: 100 },
          yPos: 0,
          direction: isReverse ? -1 : 1,
          zIndex: 1,
          reset: true,
          immediate: (n) => n === 'zIndex' || n === 'isReverse',
        };
      } else {
        return {
          yPos: 100,
          direction: isReverse ? -1 : 1,
          zIndex: -1,
          immediate: true,
        };
      }
    };

  useEffect(() => {
    let timer = null;
    function nextSlide() {
      slideForward();
      timer = setTimeout(nextSlide, 3500);
    }
    if (autoplay) {
      nextSlide();
    } else {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [autoplay]);

  /*
  transform: to(
    [props.yPos, props.direction],
    (yPos, direction) => `translate3d(0, ${yPos * direction}%, 0)`
  ),
  zIndex: props.zIndex,
  */

  const [props1, api1] = useSprings(4, curSlideFn(0));
  const [props2, api2] = useSprings(4, curSlideFn(0));
  const [props3, api3] = useSprings(4, curSlideFn(0));
  const [props4, api4] = useSprings(4, curSlideFn(0));

  const slideForward = useCallback(() => {
    currentSlide.current = (currentSlide.current + 1) % 4;
    api1.stop();
    api2.stop();
    api3.stop();
    api4.stop();
    api1.start(curSlideFn(0));
    api2.start(curSlideFn(120));
    api3.start(curSlideFn(250));
    api4.start(curSlideFn(350));
  }, [api1, api2, api3, api4]);

  const slideBack = useCallback(() => {
    currentSlide.current = currentSlide.current === 0 ? 3 : currentSlide.current - 1;
    api1.start(curSlideFn(0, true));
    api2.start(curSlideFn(120, true));
    api3.start(curSlideFn(250, true));
    api4.start(curSlideFn(350, true));
  }, [api1, api2, api3, api4]);

  return (
    <div
      onClick={() => {
        if (activeJobPost >= 0) {
          setActiveJobPost(-1);
        }
      }}
    >
      <StyledIntro>
        <div className="styled-intro-wrapper">
          <div className="rainbow-box" dangerouslySetInnerHTML={{ __html: fields.intro_text }}></div>
          <div className="image-wrapper">
            {fields.acf.intro_image.childImageSharp.gatsbyImageData && (
              <GatsbyImage
                image={fields.acf.intro_image.childImageSharp.gatsbyImageData}
                alt={fields.intro_image_alt}
              />
            )}
          </div>
        </div>
      </StyledIntro>
      <StyledImageSlider>
        <div className="full-width-background">
          <div className="inner-content slider">
            <div className={'slide slide-0'}>
              <div className="content-block" dangerouslySetInnerHTML={{ __html: fields.life_text }}></div>
              <div className={'image-slider image-1'}>
                {props1.map((props, key) => {
                  return (
                    <a.img
                      src={fields.slides.slot1[key].img.publicURL}
                      key={key}
                      style={{
                        transform: to(
                          [props.yPos, props.direction],
                          (yPos, direction) => `translate3d(0, ${yPos * direction}%, 0)`
                        ),
                        zIndex: props.zIndex,
                      }}
                    />
                  );
                })}
              </div>
              <div className={'image-slider image-2'}>
                {props2.map((props, key) => {
                  return (
                    <a.img
                      src={fields.slides.slot2[key].img.publicURL}
                      key={key}
                      style={{
                        transform: to(
                          [props.yPos, props.direction],
                          (yPos, direction) => `translate3d(0, ${yPos * direction}%, 0)`
                        ),
                        zIndex: props.zIndex,
                      }}
                    />
                  );
                })}
              </div>

              <div className={'image-slider image-3'}>
                {props3.map((props, key) => {
                  return (
                    <a.img
                      src={fields.slides.slot3[key].img.publicURL}
                      key={key}
                      style={{
                        transform: to(
                          [props.yPos, props.direction],
                          (yPos, direction) => `translate3d(0, ${yPos * direction}%, 0)`
                        ),
                        zIndex: props.zIndex,
                      }}
                    />
                  );
                })}
              </div>
              <div className={'image-slider image-4'}>
                {props4.map((props, key) => {
                  return (
                    <a.img
                      src={fields.slides.slot4[key].img.publicURL}
                      key={key}
                      style={{
                        transform: to(
                          [props.yPos, props.direction],
                          (yPos, direction) => `translate3d(0, ${yPos * direction}%, 0)`
                        ),
                        zIndex: props.zIndex,
                      }}
                    />
                  );
                })}
              </div>
              <div className="arrows">
                <button
                  className="arrow-prev arrow"
                  onClick={() => {
                    setAutoplay(false);
                    slideBack();
                  }}
                ></button>
                <button
                  className="arrow-next arrow"
                  onClick={() => {
                    setAutoplay(false);
                    slideForward();
                  }}
                ></button>
              </div>
              <div className="quote">
                <div className="quoteinner">
                  <img src={quoteImg} />
                  <div dangerouslySetInnerHTML={{ __html: fields.life_quote }} className="quote-txt"></div>
                  <div className="quote-img"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledImageSlider>
      <StyledDarkSection>
        {/* TODO: Icons in this section are FPO and need to be created, according to the Figma file */}
        <div className="full-width-background">
          <div className="inner-content">
            <div className="row">
              <div className="container" dangerouslySetInnerHTML={{ __html: fields.growth_text }} />
              <div className="flex">
                {fields.opportunities.map((item, index) => (
                  <div className="column" key={index}>
                    <img src={item.icon.publicURL} />
                    <p>{item.caption}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </StyledDarkSection>
      <StyledWhiteSection>
        <div className="full-width-background">
          <div className="inner-content">
            <div className="row">
              <div className="container" dangerouslySetInnerHTML={{ __html: fields.perks_benefits_title }} />
              <BenefitsSlider benefits={fields.benefits} />
            </div>
          </div>
        </div>
      </StyledWhiteSection>
      {jobPosts ? (
        <JobPostings>
          <div className="full-width-background">
            <div className="inner-content">
              <div className="row">
                <div className="container">
                  <h2 id="openopp">Open Opportunities</h2>
                </div>
              </div>
              <div className="row">
                {jobPosts.allWpJobPost.edges.map(({ node }, idx) => {
                  return (
                    <div key={idx} className="job">
                      <div className="left">
                        <Link to={`/agency-careers/${node.slug}/`} style={{ textDecoration: 'none' }}>
                          <h4 dangerouslySetInnerHTML={{ __html: node.title }} />
                        </Link>
                      </div>
                      <div className="right">
                        <div dangerouslySetInnerHTML={{ __html: node.custom.customExcerpt }} />
                        <Link to={`/agency-careers/${node.slug}/`} style={{ textDecoration: 'none' }}>
                          Learn More
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </JobPostings>
      ) : (
        <StyledDiv>
          <div className="no-posts">
            <p>There are currently no open positions but check back as things move fast!</p>
          </div>
        </StyledDiv>
      )}
    </div>
  );
};

export default CareerPage;
