import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import whiteHex from '../img/small-white-hex.png';

const StyledDialog = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 400px);
  max-width: 1400px;
  box-sizing: border-box;
  min-height: calc((100vw - 400px) * 0.55);
  padding: 3.13% 3.65% 2.6%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  border: 1px solid #009bce;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 20px 7px;
  align-items: flex-start;
  transition: all 200ms linear;
  z-index: 1;
  /* @media (max-width: 640px) {
    padding: 3.13% 10px 2.6%; 
  } */
  &:focus {
    outline: 0;
  }

  .img {
    flex: 0 1 500px;
    max-width: 50%;
    margin-top: 142px;
  }

  .info {
    flex: 1 1 50%;
    margin-left: 3.6%;
    .hex-icon {
      width: 35px;
      height: 40px;
      background: url(${whiteHex});
      margin: 0 auto;
    }
    .name {
      font-size: 34px;
      font-family: 'Oswald';
      color: #00a4d9;
      font-weight: 200;
      text-align: center;
    }
    .role {
      font-family: 'Oswald';
      text-align: center;
      text-transform: uppercase;
      font-weight: 200;
    }
    dl {
      font-family: 'Oswald';
      font-size: 19px;
      font-weight: 200;
    }
    dt {
      color: #00a4d9;
    }
    dd {
      margin-bottom: 1.4em;
      margin-left: 0;
    }
  }

  .closeicon {
    color: #00a5d9;
    font-size: 55px;
    transform: rotate(45deg);
    display: inline-block;
    line-height: 1;
    font-family: 'times new roman';
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    background: transparent;
    border: none;
    @media (max-width: 640px) {
      right: 10px;
      top: 0px;
      padding: 0;
    }
  }
  .closeicon:focus {
    outline: 0;
  }
  @media (min-width: 1800px) {
    min-height: 0px;
  }
  @media (max-width: 1300px) {
    width: calc(100vw - 200px);
    min-height: calc((100vw - 200px) * 0.55);
  }
  @media (max-width: 1040px) {
    .img {
      flex: 0 1 50%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    .img {
      max-width: 100%;
      width: 75%;
      margin: 40px auto 20px;
    }
  }
  @media (max-width: 600px) {
    width: calc(100vw - 100px);
    min-height: calc((100vw - 100px) * 0.55);
    .img {
      margin-top: 50px;
    }
  }
  @media (max-width: 400px) {
    width: calc(100vw - 50px);
    background-color: rgba(0, 0, 0, 1);
  }
`;

const CrewDialog = (props) => {
  const buttonEl = useRef(null);
  useEffect(() => {
    buttonEl.current.focus();
  });
  return (
    <StyledDialog
      className="abc"
      tabIndex={0}
      onKeyDown={(e) => {
        e.keyCode === 27 && props.onChangeActive(-1);
      }}
      style={{ top: props.top + 'px' }}
    >
      <button
        ref={buttonEl}
        className="closeicon"
        onClick={(e) => {
          e.stopPropagation();
          props.onChangeActive(-1);
        }}
      >
        +
      </button>
      <div className="img">
        <GatsbyImage
          image={props.fields.custom.imageCasual.localFile.childImageSharp.gatsbyImageData}
          alt={props.fields.title + ' - ' + props.fields.custom.role}
        />
      </div>
      <div className="info">
        <div className="hex-icon" />
        <div className="name">{props.fields.title}</div>
        <div className="role">{props.fields.custom.role}</div>
        <dl>
          {props.fields.custom.questions.map((item, index) => (
            <div key={index}>
              <dt>{item.question}</dt>
              <dd>{item.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    </StyledDialog>
  );
};

export default CrewDialog;
